import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { delay } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { environment } from '../../../environments/environment';
import { Navigation, NavigationApiResponse, NavigationResponse } from './navigation-cache.model';

@Injectable({
  providedIn: 'root',
})
export class NavigationCacheService {
  private readonly _navigation = new BehaviorSubject<NavigationResponse>({});

  private _isError = false;

  private readonly cacheDurationInMinutes = 30;

  get navigation$(): Observable<NavigationResponse> {
    return this._navigation.asObservable();
  }

  get isError(): boolean {
    return this._isError;
  }

  constructor(private httpClient: HttpClient) {}

  fetchData(): void {
    if (sessionStorage.getItem('navigation') === null) {
      (environment.is_real_data ? this.getRealData() : NavigationCacheService.getMockData()).subscribe({
        next: data => {
          const object = Object.fromEntries(data.map(navigationItem => [navigationItem.name, navigationItem.data]));
          this._isError = false;
          sessionStorage.setItem('navigation', JSON.stringify(Object.entries(object)));
          this._navigation.next({ data: Object.entries(object) as unknown as Navigation });
          setTimeout(() => {
            sessionStorage.removeItem('navigation');
            this._navigation.next({});
            this.fetchData();
          }, this.cacheDurationInMinutes * 1000 * 60);
        },
        error: () => {
          this._isError = true;
          this._navigation.next({ isError: true })
        }
      });
    } else {
      const navigation = JSON.parse(sessionStorage.getItem('navigation'));
      this._navigation.next({ data: navigation as unknown as Navigation });
    }
  }

  private getRealData(): Observable<NavigationApiResponse[]> {
    return this.httpClient.get<NavigationApiResponse[]>(`${environment.api_endpoint}/navigation`);
  }

  private static getMockData(): Observable<NavigationApiResponse[]> {
    return of([
      {
        _id: '61d1904ffc3de7d6ef689304',
        name: 'komentarze',
        data: [{ title: 'List do Rzymian', date: '2021/4/14', chapter: 1 }, { title: 'List do Rzymian', date: '2021/4/15', chapter: 2 }]
      },
      {
        _id: '61d191f5fc3de7d6ef689307',
        name: 'opracowania',
        data: [{ title: 'Opracowanie 1', date: '2021/4/16', chips: ['A', 'E', 'S'] }, { title: 'Opracowanie 2', date: '2021/4/18', chips: ['S'] }]
      },
      {
        _id: '61d1904ffc3de7d6ef689304',
        name: 'apologetyka',
        data: [{ title: 'Apologetyka 1', date: '2021/4/16' }, { title: 'Apologetyka 2', date: '2021/4/18' }]
      }
    ]).pipe(delay(environment.request_delay));
  }
}
